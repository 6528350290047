<template>
  <div>
    <div v-if="loading" class="h-100 d-flex align-center justify-center">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-if="!loading && detailItem">
      <div class="mb-4">
        <v-btn text @click="$router.go(-1)"
          ><v-icon small class="mr-2">mdi-arrow-left</v-icon>Back</v-btn
        >
      </div>

      <template v-if="$route.params.level === 'group'">
        <IntelDetailGroup :detailItem="detailItem" nextDetail="advisor" />
      </template>

      <template v-if="$route.params.level === 'advisor'">
        <IntelDetailAdvisor :detailItem="detailItem" nextDetail="prospect" />
      </template>

      <template v-if="$route.params.level === 'prospect'">
        <IntelDetailProspect :detailItem="detailItem" />
      </template>
    </div>
  </div>
</template>

<script>
import { API, graphqlOperation } from "aws-amplify";
import { getAdvisor, getIntelGroup, getProspect } from "@/graphql/queries";
import { mapActions, mapState } from "vuex";
import IntelDetailGroup from "@/components/intel/IntelDetailGroup";
import IntelDetailAdvisor from "@/components/intel/IntelDetailAdvisor";
import IntelDetailProspect from "@/components/intel/IntelDetailProspect";

export default {
  props: ["id"],
  components: {
    IntelDetailGroup,
    IntelDetailAdvisor,
    IntelDetailProspect,
  },
  data() {
    return {
      detailItem: null,
      level: null,
      loading: false,
      backButtonText: "",
      previous: "",
      backData: [],
      nextDetailLevel: "",
    };
  },
  computed: {
    ...mapState(["routeLevelCount"]),
  },
  async beforeRouteEnter(to, from, next) {
    // react to route changes...
    next((vm) => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      // clear your previous data.
      // re-populate data
      console.log("ENTER ENTER", from);
      vm.fromRoute = from.name;
    });
  },
  async beforeRouteUpdate(to, from, next) {
    // react to route changes...
    next((vm) => {
      // access to component's instance using `vm` .
      // this is done because this navigation guard is called before the component is created.
      // clear your previous data.
      // re-populate data
      console.log("ENTER UPDATE", from);
      vm.fromRoute = from.name;
    });
  },
  watch: {
    "$route.params.level": {
      handler: function (level) {
        console.log(level);
        this.setup(this.$route.params.id, level);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    console.log("ID", this.id);
    const level = this.$route.query.level;
    this.level = level;

    this.prev = this.$route.query.prev;
    console.log("LEVEL ", level, this.fromRoute);
    if (!this.id || !level) {
      alert("ID and Level Required");
      return;
    }

    this.setup(this.id, level);
  },
  methods: {
    ...mapActions(["setRouteLevelCount"]),
    async setup(id, level) {
      this.loading = true;
      console.log("here in setup", this.$route.params);
      // If route level is /group - make next to /advisor
      // if route level is /advisor - make next to /prospect
      // if (this.$route.params.level === "group") {
      //   this.nextDetailLevel = "advisor";
      //   this.backButtonText = "Groups";
      // } else if (this.$route.params.level === "advisor") {
      //   this.nextDetailLevel = "prospect";
      //   this.backButtonText = "Group";
      // }

      let query = getIntelGroup;
      let queryAsString = "getIntelGroup";

      if (localStorage.getItem("intelLevel") === "group") {
        this.backData = ["groups", "group", "advisor"];
      }

      if (level === "advisor") {
        query = getAdvisor;
        queryAsString = "getAdvisor";
        this.backButtonText = "Advisor";
      }

      if (level === "prospect") {
        query = getProspect;
        queryAsString = "getProspect";
        this.backButtonText = "Advisor";
      }

      if (level === "group") {
        this.backButtonText = "Groups";
      } else if (level === "advisor") {
        this.backButtonText = "Group";
      } else if (level === "prospect") {
        this.backButtonText = "Advisor";
      }

      try {
        const response = await API.graphql(graphqlOperation(query, { id: id }));
        const data = await response.data[queryAsString];
        console.log("data from query", data);
        if (data) {
          this.detailItem = data;
        }
      } catch (error) {
        console.log("Error getting group", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
