<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-5 d-flex flex-column">
        <v-card flat outlined class="flex">
          <v-card-subtitle class="pb-0 text-h6 font-weight-bold"
            >Advisor Dashboard</v-card-subtitle
          >
          <v-card-title class="pb-2">
            {{ detailItem.lastName }}, {{ detailItem.firstName }}
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <a v-if="detailItem.phone" :href="`tel:${detailItem.phone}`"
                  >{{ detailItem.phone }}<br
                /></a>
                <a v-if="detailItem.email" :href="`mailto:${detailItem.email}`"
                  >{{ detailItem.email }}<br
                /></a>
                <a
                  v-if="detailItem.companyUrl"
                  :href="detailItem.companyUrl"
                  target="_blank"
                  >{{ detailItem.companyUrl }}<br
                /></a>
              </v-col>

              <v-col>
                <span v-if="detailItem.companyName"
                  >{{ detailItem.companyName }}<br
                /></span>
                <span v-if="detailItem.streetAddress1"
                  >{{ detailItem.streetAddress1 }}<br
                /></span>
                <span v-if="detailItem.streetAddress2"
                  >{{ detailItem.streetAddress2 }}<br
                /></span>

                {{ detailItem.address.city }}, {{ detailItem.address.state }}
                {{ detailItem.address.postalCode }}
              </v-col></v-row
            >
          </v-card-text></v-card
        ></v-col
      >
      <v-col class="d-flex flex-column">
        <v-card class="d-flex flex-column flex" elevation="12">
          <v-card-text class="flex justify-center align-center">
            <div class="d-flex flex-column flex text-center">
              <div class="mb-2 text-h6 font-weight-bold text-uppercase">
                Identified
              </div>
              <div
                class="
                  mb-2
                  success--text
                  text-h5 text-md-h4 text-lg-h1
                  font-weight-bold
                "
              >
                {{
                  currencyFormatter(
                    (detailItem.aum / 100000).toFixed() * 100000
                  )
                }}
              </div>
              <div>Assets Under Management (AUM)</div>
              <v-divider class="my-2"></v-divider>
              <div
                class="
                  pa-2
                  indigo--text
                  text-h5 text-sm-h4 text-md-h2
                  font-weight-bold
                "
              >
                {{
                  detailItem.recProductsCount
                    ? detailItem.recProductsCount
                    : "0"
                }}
              </div>
              <div>Sales Opportunities</div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col class="d-flex flex-column">
        <v-card flat outlined class="flex">
          <v-card-subtitle class="mb-2 text-h6 font-weight-bold"
            >Metrics</v-card-subtitle
          >
          <v-card-text>
            <div class="d-flex flex-column justify-center">
              <!-- <div class="mb-4">
                <v-chip dark label outlined color="primary">
                  <span class="text-h3">
                    {{ detailItem.prospects.items.length }}
                  </span></v-chip
                ><span class="ml-4 font-weight-bold">Total Prospects</span>
              </div> -->
              <div class="mb-8">
                <v-chip dark label outlined color="orange darken-3">
                  <span class="text-h3">
                    {{ countProspectCompletedSurveys }}
                  </span></v-chip
                ><span class="ml-4 text-body-1">Completed Surveys</span>
              </div>
              <div class="d-flex align-center">
                <v-progress-circular
                  :rotate="-90"
                  :size="50"
                  :width="8"
                  :value="averageRiskScoreAllProspects"
                  color="primary"
                >
                  {{ averageRiskScoreAllProspects }}
                </v-progress-circular>

                <span class="ml-4 text-body-1">Average Risk Score</span>
              </div>
            </div>
          </v-card-text></v-card
        ></v-col
      >
    </v-row>
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <v-card flat outlined>
              <v-card-text>
                <p class="text-h6 font-weight-bold mb-4">Prospects</p>
                <v-data-table
                  v-if="cleanProspectsItems"
                  :headers="headers"
                  :items="cleanProspectsItems"
                  :items-per-page="10"
                  sort-by="scoreSurveyResults"
                  :sort-desc="true"
                  outlined
                  tile
                  :loading="!detailItem"
                  loading-text="Loading... Please wait"
                >
                  <template v-slot:item.scoreSurveyResults="{ value }">
                    {{ getProspectScore(value) }}
                  </template>
                  <template v-slot:item.phone="{ item }">
                    {{ item.phone ? item.phone : "--" }}
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-btn
                      small
                      text
                      color="primary"
                      :to="{
                        name: 'LeadSheet',
                        params: { id: item.id },
                      }"
                      target="_blank"
                      class="mr-4"
                      >Lead Sheet</v-btn
                    >
                    <v-btn
                      small
                      text
                      color="primary"
                      :to="{
                        name: 'PublicProspectScoreReport',
                        params: { id: item.id },
                      }"
                      target="_blank"
                      class="mr-4"
                      >Score Report</v-btn
                    >
                    <v-btn
                      small
                      text
                      color="primary"
                      :to="{
                        name: 'IntelDetail',
                        params: { level: nextDetail, id: item.id },
                        query: { level: 'prospect' },
                      }"
                      >Detail</v-btn
                    >
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col></v-row
    >
  </div>
</template>

<script>
export default {
  props: {
    detailItem: {
      type: Object,
    },
    nextDetail: {
      type: String,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Last Name",
          align: "left",
          sortable: true,
          value: "lastName",
        },
        {
          text: "First Name",
          align: "left",
          sortable: true,
          value: "firstName",
        },
        {
          text: "Email",
          align: "left",
          sortable: true,
          value: "email",
        },
        {
          text: "Phone",
          align: "center",
          sortable: false,
          value: "phone",
        },
        {
          text: "Risk Score",
          align: "center",
          sortable: true,
          value: "scoreSurveyResults",
        },
        // {
        //   text: "Sales Opportunities",
        //   align: "center",
        //   sortable: true,
        //   value: "recProductsCount",
        // },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  computed: {
    cleanProspectsItems() {
      return this.detailItem.prospects.items.filter(
        (p) => p.scoreSurveyComplete && p.email
      );
    },

    countProspectCompletedSurveys() {
      let count = 0;
      this.cleanProspectsItems.forEach((p) => {
        // a.prospects.items.forEach((p) => {
        if (p.scoreSurveyComplete) {
          count++;
        }
        // });
      });
      return count;
    },
    averageRiskScoreAllProspects() {
      let sum = 0;
      let count = 0;
      this.cleanProspectsItems.forEach((p) => {
        if (p.scoreSurveyResults) {
          const score = JSON.parse(p.scoreSurveyResults).final_score;

          if (score) {
            sum += score;
            count++;
          }
        }
      });

      const average = Math.round(sum / count);
      return average;
    },
  },
  methods: {
    getProspectScore(results) {
      if (results) {
        return JSON.parse(results).final_score;
      } else {
        return "---";
      }
    },
    currencyFormatter(value) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 0,
        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      }).format(value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
